import crypto from 'crypto';

import { MutableRefObject } from 'react';

import {
  CartItem,
  Category,
  CouponItem,
  Currency,
  DisplayOutOfStock,
  GmoTokenInput,
  PaymentInstrument,
  ProductSearchHit,
  Size,
  StandardOrder,
  Variant,
  VariantProduct,
  WishListItem,
} from '__generated__/graphql';
import { MainNavItemType } from 'groq/shared/MainNavItem';
import { ActiveCart, ActiveCartItem, ActiveCartItemProduct } from 'types/cart';
import { ProductTypeFromPDPQuery } from 'types/product';
import { getMinimalNumber } from 'utils/getMinimalNumber';
import {
  REFERRER_CATEGORY,
  REFERRER_TYPE,
  PaymentMethodsGA4Analytics,
  PaymentMethod,
  isServer,
} from 'utils/constants';
import {
  Dimensions,
  AnalyticsDimensions,
  PageViewPayload,
  AnalyticsPageType,
  pathType,
  AnalyticsProduct,
  AnalyticsCartProduct,
  AnalyticsProductImage,
  ItemAvailabilityValue,
  AdditionalDimensionArgs,
  VariantAvailability,
  ProductToAnalyticsProductType,
  SignUpFormAnalyticsIdentifiers,
  SignUpFormAnalyticsEventType,
  SignUpLocations,
  EmailSignUpActions,
  EmailSignUpLocations,
  ProductToTransform,
  ItemAvailabilitySchema,
  ItemProductInterface,
} from 'types/analyticTypes';
import { Maybe } from '__generated__/graphql-sanity';

import { capitalize } from './capitalize';
import { getProductPrice } from './getProductPrice';
import { isNotNullish } from './isNotNullish';
import { isNumber, beginsWithNumber } from './isNumber';
import { DIGITAL_GIFT_CARD_NAME, getSizesFromSizeGroups } from './products';
import {
  lowerCaseOrBlank,
  lowerCaseRemoveSpecialChars,
  upperCaseOrBlank,
} from './string';
import { getGa4Item } from './ga4Items';

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
    Multipayment: {
      getToken: (
        creditCard: GmoTokenInput,
        cb: any
      ) => {
        resultCode: `000` | number;
        tokenObject: {
          isSecurityCodeSet: boolean | string;
          maskedCardNo: string;
          toBeExpiredAt: string;
          token: string;
        };
      };
      init: (apiKey: string) => void;
    };
    onAmazonLoginReady?: () => void;
    OffAmazonPayments: {
      Button: (
        amazonPayButtonId: string,
        amazonPayMerchantId: string,
        params: any
      ) => void;
      Widgets: {
        AddressBook: (params: any) => void;
        Wallet: (params: any) => void;
      };
    };
    Razorpay: any;
  }
}

export const AnalyticsEvents = {
  ACCOUNT: 'account',
  ADD_ITEM_LIST_TO_CART: 'add_to_cart',
  ADD_PAYMENT_INFO: 'add_payment_info',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_TO_CART: 'add to cart',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  BEGIN_CHECKOUT: 'begin_checkout',
  CHECKOUT: 'checkout', // Collection - review, billing, address,
  CHANGE_COLOR: 'change_color',
  CHOOSE_SIZE: 'choose_size',
  ELEVATED_PDP_IMPRESSION: 'elevated pdp impression',
  EMAIL_SIGNUP: 'email signup',
  FOOTER_LINK_CLICKED: 'footer link clicked', // Clarity around Sanity needed
  GA4_CustomEvent: 'GA4customEvent',
  GA4_GENERIC: 'GA4generic',
  GA4EC_AddItemToCart: 'GA4EC_AddItemToCart',
  GA4EC_AddItemToWishlist: 'GA4EC_AddItemToWishlist',
  GA4EC_AddPaymentInfo: 'GA4EC_AddPaymentInfo',
  GA4EC_AddShippingInfo: 'GA4EC_AddShippingInfo',
  GA4EC_BeginCheckout: 'GA4EC_BeginCheckout',
  GA4EC_ItemListClick: 'GA4EC_ItemListClick',
  GA4EC_ItemListView: 'GA4EC_ItemListView',
  GA4EC_ItemView: 'GA4EC_ItemView',
  GA4EC_PromotionSelect: 'GA4EC_PromotionSelect',
  GA4EC_PromotionView: 'GA4EC_PromotionView',
  GA4EC_OrderConfirmation: 'GA4EC_OrderConfirmation',
  GA4EC_RemoveItemFromCart: 'GA4EC_RemoveItemFromCart',
  GA4EC_ViewCart: 'GA4EC_ViewCart',
  GA4EC_Sizechart: 'sizechart',
  GA4EC_Stylitics: 'stylitics',
  GA4_PRODUCT_CLICK: 'product_click',
  GA4_IMAGE_OPENED: 'image_opened',
  GA4EC_NewsletterImpression: 'newsletter_impression',
  GA4EC_Refinement: 'refinement',
  GENERAL: 'general',
  GA4EC_SignUp: 'sign_up',
  GA4EC_SignUpFormOpen: 'sign_up_form_open',
  HEADER_LINK_CLICKED: 'header link clicked',
  IMAGE_VIEWED: 'image_viewed',
  LANGUAGE_CHANGED: 'language changed',
  LOGIN: 'login',
  LOGOUT: 'logout',
  MINICART: 'minicart',
  MORE_INFORMATION: 'more_information',
  NAVIGATION_CLICK: 'navigation_click',
  ORDER_CONFIRMATION: 'order confirmation', // https://pumaglobal.atlassian.net/wiki/spaces/CKS/pages/5421466739/24-02-2021+-+Headless+Enhancements+-+DRAFT
  ORDER_LOOKUP: 'order lookup', // TODO, not implemented
  PAYMENT: 'payment', // Payment Collection, // https://pumaglobal.atlassian.net/browse/CBC-1221
  PAYMENT_TYPE_SELECTED: 'payment type selected',
  PDP_INTERACTION: 'pdp interaction',
  PRODUCT_CLICKED: 'product clicked', // We clicka  product on a PLP/Search/...
  PRODUCT_DISCOVERY_CHANNEL: 'product_discovery_channel',
  PRODUCT_RECOMMENDER_CLICKED: 'product recommender click', // einstein recommender clicked
  PRODUCT_RECOMMENDER_VIEWED: 'product recommender view', // einstein recommender viewed
  PRODUCT_LIST_LOADED: 'product list loaded', // First load of a PLP/search
  PRODUCT_LIST_REFRESHED: 'product list refreshed', // When a filter/sorting is applied
  PRODUCT_LIST_UPDATED: 'product list updated', // When we paginate further along the list
  PRODUCT_VIEWED: 'product viewed', // Navigating to a PDP
  PROMOTION_CLICK: 'promotion click',
  PROMOTION_VIEW: 'promotion view',
  PROMO_CODE_SUBMITTED: 'promo code submitted', // Used when Promo Codes are entered in the Cart
  PIXLEE: 'pixlee',
  QUANTITY_CHANGE: 'quantity_change',
  PURCHASE: 'purchase',
  RATINGS_AND_REVIEWS: 'ratings_and_reviews',
  REFINEMENT_SELECTED: 'refinement selected',
  REMOVE_FROM_CART: 'remove from cart',
  REMOVE_ITEM_FROM_CART: 'remove_from_cart',
  SCROLL: 'scroll',
  SEARCH: 'search',
  SEARCH_MODAL_IMPRESSION: 'search modal impression', // opening the search flyout
  SEARCH_TRENDING_SEARCH_TERM_CLICK: 'trending searches', // ranked search terms from Google
  SEARCH_SUGGESTED_SEARCH_TERM_CLICK: 'suggested search term', // search terms returned as suggestions
  SEARCH_RECOMMENDED_PRODUCT_CLICK: 'suggested product', // products from the recommender (yes, "suggested product" is the event name they want)
  SEARCH_RECENTLY_VIEWED_PRODUCT_CLICK: 'recently viewed click', // products recently viewed by the customer
  SEARCH_OPEN: 'search_open',
  SEARCH_SUGGESTED_PRODUCT_CLICK: 'related product click', // products returned as search suggestions
  SELECT_ITEM: 'select_item',
  SELECT_PROMOTION: 'select_promotion',
  SIGN_UP_FORM_ERROR: 'sign_up_form_error',
  SIGN_UP_FORM_CLOSE: 'sign_up_form_close',
  SMS_OPT_IN: 'sms signup',
  SORT_SELECTED: 'sort selected',
  STYLITICS_INTERACTION: 'stylitics interaction', // Clicking the View Details CTA on the "Shop the Model" bundle for Stylitics
  THUMBNAIL_ARROW: 'thumbnail_arrow',
  VIDEO_START: 'video_start',
  VIDEO_PROGRESS: 'video_progress',
  VIDEO_COMPLETE: 'video_complete',
  VIEW_CART: 'view_cart',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_PROMOTION: 'view_promotion',
  SHOW_MORE_ALL: 'show_more_all',
  WISHLIST_TRANSFER: 'wishlist_transfer',
} as const;

type AnalyticsEventKeys = keyof typeof AnalyticsEvents;

type PromoTileProps = {
  id: string;
  variantProduct: {
    id: string;
  };
};

type HandleIntersectionPLPProps = {
  productId: string;
  intersectedProductsRef: MutableRefObject<
    Array<ProductSearchHit | PromoTileProps>
  >;
  intersectAllProductsRef: MutableRefObject<
    Array<ProductSearchHit | PromoTileProps>
  >;
  listProducts: ProductSearchHit[];
  screenSize: number;
  currency: Currency & {
    code: string;
    symbol: string;
    decimalsToDisplay: number;
  };
  offset: number;
  pageviewEventHasFired: boolean;
  currentCategory?: Category | null;
  searchTerm?: string;
};

export type AnalyticsEventValues = (typeof AnalyticsEvents)[AnalyticsEventKeys];

export const getPageType = (url: string): AnalyticsPageType => {
  let pageType;

  if (url.includes('/checkout')) {
    if (url.includes('/confirmation')) pageType = 'order confirmation';
    else if (url.includes('/payment')) pageType = 'payment';
    else pageType = 'checkout';
  } else if (url.includes('/pd/')) {
    pageType = 'pdp';
  } else if (url.includes('/account')) {
    pageType = 'account';
  } else if (url.includes('/search')) {
    pageType = 'search';
  } else if (url.includes('/wishlist')) {
    pageType = 'other';
  } else if (url.includes('/returns')) {
    pageType = 'other';
  } else if (url.includes('/cart')) {
    pageType = 'cart';
  } else if (url.includes('/help')) {
    pageType = 'content';
  } else if (url.split('/').length === 3) {
    pageType = 'home';
  } else {
    pageType = 'plp';
  }

  return pageType;
};

export const getG4aPath = (url, pathType: pathType) => {
  const splittedUrl = url.split('?');
  if (pathType === 'page path') return splittedUrl[0];
  else return splittedUrl[1];
};

export const getCustomerId = (payload: PageViewPayload) => {
  const storedCustomerId = localStorage.getItem('customerID');
  if (!payload.customerAuth) {
    return storedCustomerId || `guest_${payload.customerId}`;
  }
  if (!storedCustomerId || storedCustomerId !== payload.customerId) {
    localStorage.setItem('customerID', payload.customerId);
  }
  return localStorage.getItem('customerID') || '';
};

const ga4GenericEvent = (
  url: string,
  payload: PageViewPayload,
  pageType: AnalyticsPageType
) => {
  const isGuest = String(!payload.customerAuth);

  const query_string = getG4aPath(url, 'query string');

  const referrerCategory =
    window.localStorage.getItem(REFERRER_CATEGORY) ?? undefined;
  const referrerType = window.localStorage.getItem(REFERRER_TYPE) ?? undefined;

  window.dataLayer.push({
    event: AnalyticsEvents.GA4_GENERIC,
    page_type: pageType,
    page_title: payload.pageTitle,
    page_path: getG4aPath(url, 'page path'),
    page_location: payload.currentURL,
    query_string,
    local_language: payload.language,
    custom_user_id: getCustomerId(payload),
    store_view: `${payload.language}-${payload.country.toUpperCase()}`,
    is_guest: isGuest,
    release_version: window.sessionStorage.getItem('analyticsReleaseVersion'),
    web_frontend: 'cybercat',
    index_status: getIndexStatus(),
    gsm_code: getGsmCode(payload.country),
    hashed_email: hashEmail(payload.customerEmail),
    referrer_category: referrerCategory,
    referrer_type: referrerType,
  });
};

export const handleIntersectionPLP = ({
  productId,
  intersectedProductsRef,
  intersectAllProductsRef,
  listProducts,
  screenSize,
  currency,
  offset,
  pageviewEventHasFired,
  currentCategory,
  searchTerm,
}: HandleIntersectionPLPProps) => {
  const productFinder = intersectedProductsRef.current.some(
    obj => obj?.variantProduct?.id === productId
  );
  const productFinderAux = intersectAllProductsRef.current.some(
    obj => obj?.variantProduct?.id === productId
  );

  if (productFinder || productFinderAux) return;

  const filterProductsById = listProducts?.find(
    product => productId === product?.variantProduct?.id
  );
  if (filterProductsById) {
    intersectedProductsRef.current = [
      ...intersectedProductsRef.current,
      filterProductsById,
    ];
    intersectAllProductsRef.current = [
      ...intersectAllProductsRef.current,
      filterProductsById,
    ];
  } else {
    if (productId === 'positioned-promo-tile') {
      const objectPromoTile: ProductSearchHit | PromoTileProps = {
        id: productId,
        variantProduct: {
          id: productId,
        },
      };
      intersectedProductsRef.current = [
        ...intersectedProductsRef.current,
        objectPromoTile,
      ];
      intersectAllProductsRef.current = [
        ...intersectAllProductsRef.current,
        objectPromoTile,
      ];
    }
  }

  if (intersectedProductsRef.current.length === screenSize) {
    const filterIntersectedProductsRef = intersectedProductsRef.current?.filter(
      product => product.id !== 'positioned-promo-tile'
    );
    const listItems = filterIntersectedProductsRef.map((product, index) => {
      return transformListProductToAnalyticsItem({
        product: product.variantProduct,
        currency: currency.code,
        quantity: 1,
        itemListId: searchTerm ? searchTerm : currentCategory?.id,
        itemListName: searchTerm ? searchTerm : currentCategory?.name,
        index: offset + index,
        categories: {
          // NOTE: send `item_category` anyway but with undefined value
          item_category: undefined,
        },
      });
    });
    if (pageviewEventHasFired) {
      event(AnalyticsEvents.GA4EC_ItemListView, {
        event_name: AnalyticsEvents.VIEW_ITEM_LIST,
        ecommerce: {
          item_list_id: searchTerm ? searchTerm : currentCategory?.id,
          item_list_name: searchTerm ? searchTerm : currentCategory?.name,
          // NOTE:  "item_id_ep" & "item_name_ep" send with data
          //        only on PDP, in PLP send with "undefined" value
          item_id_ep: undefined,
          item_name_ep: undefined,
          items: listItems,
        },
      });
    }
    intersectedProductsRef.current = [];
  }
};

export const pageview = (
  url: string,
  payload: PageViewPayload,
  pageTypeOverride?: AnalyticsPageType
) => {
  const pageType = pageTypeOverride ?? getPageType(url);

  ga4GenericEvent(url, payload, pageType);

  event(AnalyticsEvents.GENERAL, {
    pageType,
    pageTitle: payload.pageTitle,
    language: payload.language,
    customerID: payload.customerId,
    customerAuth: payload.customerAuth,
    customerEmail: payload.customerEmail,
    customerNo: payload.customerNo,
    storeView: payload.country,
    country: payload.country.toUpperCase() + ' Shop',
    environment: process.env.STAGE,
    releaseVersion: window.sessionStorage.getItem('analyticsReleaseVersion'),
    frontend: 'cybercat',
    breadcrumbs: payload.breadcrumbs,
    viewport: getViewport(),
    searchQuery: payload.searchQuery,
    cart: payload.cart,
    currentURL: payload.currentURL,
  });
};

const gsmCodes = {
  ca: 'CARE0003',
  us: 'USRE0001',
  uk: 'GBRE0006',
  jp: 'JPRE0006',
  in: 'INRE0001',
  au: 'AURE0001',
  nz: 'NZRE0002',
} as const;

const getGsmCode = countryCode => {
  const country = countryCode.split('-')[0];

  return gsmCodes[country];
};

export const hashEmail = (email: string | undefined): string | undefined => {
  if (email) {
    const hash = crypto.createHash('sha256');
    hash.update(email);

    const hashedEmail = hash.digest('hex');

    return hashedEmail;
  }
};

const getIndexStatus = () => {
  if (document) {
    const robots = document.getElementsByName('robots')[0]?.['content'];
    if (robots === 'noindex, nofollow') {
      return 'noindex';
    } else {
      return 'index';
    }
  }
};

const getViewport = () => {
  const minScreenSize = Math.min(window.screen.width, window.screen.height);

  if (minScreenSize < 480) {
    return 'mobile';
  } else if (minScreenSize < 1024) {
    return 'tablet';
  }
  return 'desktop';
};

export const addPrefixToAnalyticProp = (
  prop: Maybe<string> | undefined,
  prefix = 'a'
) => {
  if (prop) return beginsWithNumber(prop) ? `${prefix}${prop}` : prop;
};

export const activateOptimize = () => {
  window.dataLayer.push({
    event: 'optimize.activate',
  });
};

export const event = (type: AnalyticsEventValues, payload?: any) => {
  const isGA4Event =
    type.startsWith('GA4EC_') || type === AnalyticsEvents.GA4_CustomEvent;
  const eventData = {
    event: type,
    ...(!isGA4Event && {
      environment: process.env.STAGE,
      release_version: window.sessionStorage.getItem('analyticsReleaseVersion'),
      viewport: getViewport(),
    }),
    ...payload,
  };
  if (!isServer) window.dataLayer.push(eventData);
};

export const getItemAvailability = (
  val?: DisplayOutOfStock
): ItemAvailabilityValue | '' => {
  if (!val) return ItemAvailabilitySchema.instock;
  const itemAvailabilityValue = Object.keys(val).filter(key => val[key])[0];
  return ItemAvailabilitySchema[itemAvailabilityValue] ?? '';
};

export const getAddToCardReferrer = (ean: string | undefined | null) => {
  if (ean && window?.dataLayer?.length) {
    const listDataLayer = window.dataLayer.filter(
      e => e.event === AnalyticsEvents.ADD_TO_CART
    );
    const vectorProductsEvent = listDataLayer.map(
      (element: Record<string, any>) => {
        const rObj = { ean: '', dimension100: '' };
        rObj['ean'] = element?.ecommerce?.add?.products[0]?.ean;
        rObj['dimension100'] =
          element?.ecommerce?.add?.products[0]?.dimension100;

        return rObj;
      }
    );
    const result = vectorProductsEvent.filter(item => item.ean === ean);
    return result[0]?.dimension100;
  } else {
    return '';
  }
};
const getAdditionalAnalyticsDimensions = ({
  sizeName,
  displayOutOfStock,
  assortmentAvailability,
  isProductMarkedDown,
  orderable,
  ean,
}: AdditionalDimensionArgs) => ({
  [Dimensions.d88]: upperCaseOrBlank(sizeName),
  [Dimensions.d92]: getItemAvailability(displayOutOfStock || undefined),
  [Dimensions.d94]: lowerCaseOrBlank(assortmentAvailability),
  [Dimensions.d96]: lowerCaseOrBlank(`${isProductMarkedDown ?? ''}`),
  [Dimensions.d97]: lowerCaseOrBlank(`${orderable}`),
  [Dimensions.d100]: getAddToCardReferrer(ean),
});

const getAnalyticsDimensions = (
  product: VariantProduct
): AnalyticsDimensions => {
  const productPrice = product?.productPrice?.price;
  const productSalePrice = product?.productPrice?.salePrice;
  const isProductMarkedDown = !!(
    productPrice &&
    productSalePrice &&
    productSalePrice < productPrice
  );

  return {
    [Dimensions.d14]: lowerCaseOrBlank(product.name),
    [Dimensions.d15]: lowerCaseOrBlank(product.masterId),
    [Dimensions.d16]: lowerCaseOrBlank(product.sportName),
    [Dimensions.d17]: lowerCaseOrBlank(product.technology),
    [Dimensions.d18]: lowerCaseOrBlank(product.gender),
    [Dimensions.d19]: lowerCaseOrBlank(product.ageGroup),
    [Dimensions.d20]: lowerCaseOrBlank(product.lineName),
    [Dimensions.d21]: lowerCaseOrBlank(product.businessSegment),
    [Dimensions.d22]: lowerCaseOrBlank(product.division),
    [Dimensions.d23]: lowerCaseOrBlank(product.articleGroup),
    [Dimensions.d24]: lowerCaseOrBlank(product.articleType),
    [Dimensions.d25]: lowerCaseOrBlank(product.colorDescription),
    [Dimensions.d76]: lowerCaseOrBlank(product.colorName),
    [Dimensions.d79]: lowerCaseOrBlank(product.businessUnit),
    [Dimensions.d80]: lowerCaseOrBlank(product.businessSubSegment),
    [Dimensions.d81]: lowerCaseOrBlank(product.franchise),
    [Dimensions.d82]: lowerCaseOrBlank(product.collectionMark),
    ...getAdditionalAnalyticsDimensions({
      sizeName: product.size.label,
      orderable: product.orderable,
      displayOutOfStock: product.displayOutOfStock,
      assortmentAvailability: product.assortmentAvailability,
      isProductMarkedDown,
      ean: product.ean,
    }),
  };
};

const getVariantAvailability = (
  sizes?: Size[]
): VariantAvailability | undefined => {
  if (!sizes) return;

  const amountOrderable = sizes?.filter(size => size.orderable === true);

  if (!amountOrderable.length) return 'unavailable';
  if (amountOrderable.length === sizes.length) return 'available';
  if (amountOrderable.length < sizes.length) return 'partially available';
};

export const transformProductToAnalyticsProduct = ({
  categoryId,
  desiredSize,
  includeExtraAnalyticsDimensions = false,
  product,
  variant,
}: ProductToAnalyticsProductType): AnalyticsProduct => {
  const variantPrice = variant?.productPrice?.price;
  const variantSalePrice = variant?.productPrice?.salePrice;
  const sizes = getSizesFromSizeGroups(variant.sizeGroups);
  const size = sizes.find(s => s.id === desiredSize);

  const additionalDimensions = includeExtraAnalyticsDimensions
    ? getAdditionalAnalyticsDimensions({
        sizeName: desiredSize ? size?.label : '',
        displayOutOfStock: product?.displayOutOfStock,
        assortmentAvailability: getVariantAvailability(sizes),
        isProductMarkedDown: !!(
          variantPrice &&
          variantSalePrice &&
          variantSalePrice < variantPrice
        ),
        orderable: size ? size.orderable : variant.orderable,
        ean: variant.ean,
      })
    : {};

  return {
    id: variant.id,
    variant: size?.productId ?? variant.variantId ?? '',
    ean: variant.ean ?? '',
    name: variant.name || product?.name || '',
    price: getProductPrice(variant),
    full_price: variantPrice,
    brand: product?.brand || 'PUMA',
    category: categoryId || product?.primaryCategoryId || '',
    ...additionalDimensions,
  };
};

export const transformVariantProductToAnalyticsProduct = (
  variantProduct: ActiveCartItemProduct,
  includeExtraAnalyticsDimensions = false
): AnalyticsProduct => ({
  id: variantProduct.styleNumber,
  variant: variantProduct.id,
  ean: variantProduct.ean ?? '',
  name: variantProduct?.name,
  price: getProductPrice(variantProduct),
  brand: variantProduct?.brand ?? 'PUMA',
  category: variantProduct?.primaryCategoryId ?? '',
  full_price: variantProduct.price,
  ...(includeExtraAnalyticsDimensions && {
    ...getAdditionalAnalyticsDimensions({
      sizeName: variantProduct.size.label,
      displayOutOfStock: variantProduct.displayOutOfStock,
      assortmentAvailability: variantProduct.assortmentAvailability,
      isProductMarkedDown: !!(
        variantProduct.salePrice &&
        variantProduct.salePrice < variantProduct.price
      ),
      orderable: variantProduct.orderable,
      ean: variantProduct.ean,
    }),
  }),
});

export const transformCartProductToAnalyticsCartProduct = (
  item: ActiveCartItem | CartItem,
  includeExtraAnalyticsDimensions = false
): AnalyticsCartProduct | undefined => {
  if (item.__typename === 'ProductCartItem') {
    let coupon = '';
    if (item.adjustments) {
      coupon = item.adjustments
        .map(a => `${a.couponCode}|${a.promotionId}`)
        .join(', ');
    }

    const analyticsCartProduct: AnalyticsCartProduct = {
      coupon,
      id: item.product.styleNumber,
      variant: item.product.id,
      ean: item.product.ean ?? '',
      name: item.product.name,
      price: item.inCartPrice,
      brand: item.product.brand ?? 'PUMA',
      category: item.product.primaryCategoryId ?? '',
      quantity: item.quantity,
      full_price: item.product.price,
      unitDiscount: item.product.salePrice
        ? parseFloat((item.product.price - item.product.salePrice).toFixed(2))
        : undefined,
      ...(includeExtraAnalyticsDimensions && {
        ...getAnalyticsDimensions(item.product as VariantProduct),
      }),
      imageUrl: item.product.images[0].href,
    };

    if ('taxRate' in item && isNotNullish(item.taxRate)) {
      analyticsCartProduct.taxRate = parseFloat(
        Number(item.taxRate).toFixed(2)
      );
    }

    return analyticsCartProduct;
  }
  if (item.__typename === 'GiftCardCartItem') {
    return {
      id: '000001_01',
      variant: '',
      ean: '',
      name: DIGITAL_GIFT_CARD_NAME,
      price: item.amount,
      brand: 'PUMA',
      category: '',
      quantity: 1,
      recipient_email: item.recipientEmail,
      recipient_name: item.recipientName,
      imageUrl: item.imageUrl,
    };
  }
};

export const transformListProductToAnalyticsProduct = (
  product: ProductSearchHit,
  i: number,
  list?: string | null,
  categoryId?: string | null
): AnalyticsProduct | null => {
  if (!product.masterProduct) return null;
  const swatch = product.color;

  const selectedVariation = product.variantProduct as Variant;

  return {
    id: `${product.masterId}_${swatch}`,
    variant: product.id,
    ean: selectedVariation?.ean ?? '',
    name: product.masterProduct.name,
    price: getProductPrice(selectedVariation),
    brand: product.masterProduct.brand ?? 'PUMA',
    position: isNumber(i) ? i + 1 : undefined,
    list: list ?? '',
    category: categoryId ?? '',
  };
};

export const toAnalyticsCart = (data: ActiveCart) => ({
  total: data?.estimatedTotal ?? 0,
  items:
    data?.products
      ?.map(x => transformCartProductToAnalyticsCartProduct(x))
      .filter(isNotNullish) ?? [],
  items_qty:
    data?.products.reduce(
      (acc, item) =>
        acc + (item.__typename === 'ProductCartItem' ? item.quantity : 1),
      0
    ) ?? 0,
});

export const getCommonFitAnalyticsData = (
  shopLanguage = '',
  shopCountry = '',
  currency = '',
  userId = '',
  sessionId
) => ({
  shopLanguage,
  shopCountry,
  currency,
  userId,
  sessionId: sessionId || userId,
});

export const getOrderConfirmationFitAnalyticsData = (products: CartItem[]) => {
  return {
    products: products
      .filter((cartItem: CartItem) => cartItem.__typename === 'ProductCartItem')
      .map((cartItem: CartItem) => {
        let productCartItem: VariantProduct;
        if (
          cartItem.__typename === 'ProductCartItem' &&
          cartItem.product.__typename
        ) {
          productCartItem = cartItem.product;
        } else {
          return cartItem;
        }

        return {
          purchasedSize: productCartItem.size.label,
          shopArticleCode: productCartItem.upc ?? '',
          productId: productCartItem.styleNumber,
          price: cartItem.inCartPrice,
          quantity: cartItem.quantity,
          ean: productCartItem.ean ?? '',
        };
      }),
  };
};

export const getPDPFitAnalyticsData = (
  masterProduct: ProductTypeFromPDPQuery,
  variation: Variant,
  swatch: string
) => ({
  currentProductId: variation.styleNumber ?? '',
  mainThumbnail: variation.images[0]?.href ?? '',
  allProductIds: masterProduct?.variations.map(
    variation => variation.styleNumber ?? ''
  ),
  sizes: masterProduct?.variations
    .filter(variation => variation.colorValue === swatch)
    .map(variation => {
      const sizes = getSizesFromSizeGroups(variation.sizeGroups);
      return {
        length: null,
        values: sizes.map(size => size.label),
        availability: sizes.reduce((acc, curr) => {
          return { ...acc, [curr.label]: curr.orderable };
        }, {}),
      };
    }),
});

export const getAnalyticsImage = (image: string, defaultImage: string) => {
  // TO DO: Define approach in GA4 when have image comes from fluidconfigure.
  // For now we use the original product image
  const isPersonalizedImage = !!image.includes('fluidconfigure');
  const analyticsImage = isPersonalizedImage
    ? transformImageUrlToImageInteractionAnalyticsEvent(defaultImage)
    : transformImageUrlToImageInteractionAnalyticsEvent(image);
  return analyticsImage;
};

export const transformImageUrlToImageInteractionAnalyticsEvent = (
  imageURL: string
): AnalyticsProductImage => {
  const [styleID, colorID, imageView, fndImageRegion, imageRegion] = imageURL
    .split('/global/')[1]
    ?.split('/');

  // Here we have to account for two types of URL

  // normal: images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_2000,h_2000/global/374915/01/mod03/fnd/PNA/fmt/png
  // fnd: images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_2000,h_2000/global/374915/01/fnd/PNA/fmt/png

  // The `fnd` type is one part shorter than the normal URLs so we have to
  // account for the shifting in position of some destructured variables

  const isFndView = imageView === 'fnd';

  return {
    imageURL,
    imageType: isFndView ? 'fnd' : imageView.slice(0, -2),
    imageNumber: isFndView ? 'unavailable' : imageView.slice(-2),
    imageRegion: isFndView ? fndImageRegion : imageRegion,
    productID: `${styleID}_${colorID}`,
    styleID,
    colorID,
  };
};

/*
 *  footer navigation
 */
export const footerNavigationEvent = ({
  parentNavigation,
  linkClicked = '',
}: {
  parentNavigation: string;
  linkClicked?: string;
}) => {
  event(AnalyticsEvents.FOOTER_LINK_CLICKED, {
    parentNavigation,
    linkClicked,
  });
  AnalyticsCustomEvent({
    event_name: AnalyticsEvents.NAVIGATION_CLICK,
    user_action: 'footer',
    nav_path: parentNavigation.toLocaleLowerCase(),
  });
};

export const emailSignUpEvent = (
  action: EmailSignUpActions,
  location?: keyof typeof EmailSignUpLocations
) => {
  if (!location) return;

  event(AnalyticsEvents.EMAIL_SIGNUP, {
    emailAction: action,
    signUpLocation: EmailSignUpLocations[location],
  });
};

export const signUpFormAnalyticsEvent = ({
  identifier,
  action,
  location,
}: SignUpFormAnalyticsEventType) => {
  if (!location) return;
  const signUpLocation = SignUpLocations[location];
  switch (identifier || SignUpFormAnalyticsIdentifiers.EMAIL_SIGNUP) {
    case SignUpFormAnalyticsIdentifiers.SMS_OPT_IN: {
      event(AnalyticsEvents.SMS_OPT_IN, {
        smsAction: `sms ${action}`,
        signUpLocation,
      });
      break;
    }
    case SignUpFormAnalyticsIdentifiers.EMAIL_SIGNUP: {
      event(AnalyticsEvents.EMAIL_SIGNUP, {
        emailAction: `email ${action}`,
        signUpLocation,
      });
      break;
    }
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unhandled Sign Up Form Identifier: ${identifier}`);
  }
};

export type AddPaymentInfoAnalyticsEvent = {
  currency: string;
  products: any[];
  couponItems: CouponItem[];
  paymentInstruments: PaymentInstrument[];
};

export const addPaymentInfoAnalyticsEvent = ({
  currency,
  products,
  couponItems,
  paymentInstruments,
}: AddPaymentInfoAnalyticsEvent) => {
  const value = getCombinedProductsValue(products);
  const items = products.map(product => {
    const ga4Item = getGa4Item(product.product?.styleNumber);
    return transformListProductToAnalyticsItem({
      product: product.product,
      currency: currency,
      quantity: product.quantity,
      categories: {
        item_category: product.product?.primaryCategoryId,
      },
      size: product.product?.size?.label,
      adjustments: product.adjustments,
      itemListId: ga4Item?.lid,
      itemListName: ga4Item?.lname,
      index: ga4Item?.idx,
      creativeName: ga4Item?.cname,
      creativeSlot: ga4Item?.cslot,
      promotionId: ga4Item?.pid,
      promotionName: ga4Item?.pname,
    });
  });

  const paymentType =
    paymentInstruments && paymentInstruments.length > 0
      ? paymentInstruments
          .map(c => convertToGA4PaymentMethod(c.type as PaymentMethod))
          .join('|')
      : undefined;

  event(AnalyticsEvents.GA4EC_AddPaymentInfo, {
    event_name: AnalyticsEvents.ADD_PAYMENT_INFO,
    ecommerce: {
      currency: currency,
      value: Number(value.toFixed(2)),
      coupon:
        couponItems && couponItems.length > 0
          ? couponItems.map(c => c.code).join('|')
          : undefined,
      payment_type: paymentType,
      items,
    },
  });
};

export type ViewCartAnalyticsEvent = {
  currency: string;
  products: any[];
  couponItems: CouponItem[];
};

const getCombinedProductsValue = (products: any[]): number => {
  return products.reduce(
    (acc, product) =>
      acc + product.inCartPrice * (product.quantityInCart ?? product.quantity),
    0
  );
};

export const viewCartAnalyticsEvent = ({
  currency,
  products,
  couponItems,
}: ViewCartAnalyticsEvent) => {
  const value = getCombinedProductsValue(products);
  const items = products.map(p => {
    const ga4Item = getGa4Item(p.product?.styleNumber);
    return transformListProductToAnalyticsItem({
      product: p.product,
      currency,
      quantity: p.quantity,
      categories: {
        item_category: p.product?.primaryCategoryId,
      },
      size: p.product?.size?.value,
      adjustments: p.adjustments,
      itemListId: ga4Item?.lid,
      itemListName: ga4Item?.lname,
      index: ga4Item?.idx,
      creativeName: ga4Item?.cname,
      creativeSlot: ga4Item?.cslot,
      promotionId: ga4Item?.pid,
      promotionName: ga4Item?.pname,
    });
  });
  event(AnalyticsEvents.GA4EC_ViewCart, {
    event_name: AnalyticsEvents.VIEW_CART,
    ecommerce: {
      currency,
      value: Number(value.toFixed(2)),
      coupon:
        couponItems && couponItems.length > 0
          ? couponItems.map(c => c.code).join('|')
          : undefined,
      items,
    },
  });
};

export const addShippingInfoEvent = (
  currency: string,
  shipmentMethod: string,
  couponItems: CouponItem[],
  products: any[]
) => {
  const value = getCombinedProductsValue(products);
  const items = products.map(({ product, quantityInCart, adjustments }) => {
    const size = product?.size?.label || '';
    const ga4Item = getGa4Item(product?.styleNumber);
    return transformListProductToAnalyticsItem({
      product,
      currency,
      quantity: quantityInCart,
      categories: {
        item_category: product?.primaryCategoryId,
      },
      size,
      adjustments,
      itemId: product?.styleNumber,
      itemListId: ga4Item?.lid,
      itemListName: ga4Item?.lname,
      index: ga4Item?.idx,
      creativeName: ga4Item?.cname,
      creativeSlot: ga4Item?.cslot,
      promotionId: ga4Item?.pid,
      promotionName: ga4Item?.pname,
    });
  });
  const shippingTier = shipmentMethod.toLowerCase();
  event(AnalyticsEvents.GA4EC_AddShippingInfo, {
    event_name: AnalyticsEvents.ADD_SHIPPING_INFO,
    ecommerce: {
      currency,
      value: Number(value.toFixed(2)),
      coupon:
        couponItems && couponItems.length > 0
          ? couponItems.map(c => c.code).join('|')
          : undefined,
      shipping_tier: shippingTier,
      items,
    },
  });
};
export const addToWishListEvent = (
  currency: string,
  wishListItem: WishListItem,
  quantity?: number,
  imageType?: string,
  imageNumber?: string
) => {
  const productPrice = wishListItem.product?.productPrice;
  const price = productPrice
    ? getMinimalNumber(
        productPrice.price,
        productPrice.promotionPrice,
        productPrice.salePrice
      )
    : undefined;
  const value = (quantity ?? 0) * (price ?? 0);
  const product = wishListItem.product;

  const size = product?.size?.label;
  const item = transformListProductToAnalyticsItem({
    product: product,
    currency,
    quantity: quantity ?? 0,
    categories: {
      item_category: product?.primaryCategoryId,
    },

    size,
    index: undefined,
    itemId: product.styleNumber,
  });
  event(AnalyticsEvents.GA4EC_AddItemToWishlist, {
    event_name: AnalyticsEvents.ADD_TO_WISHLIST,
    ecommerce: {
      currency,
      value: Number(value.toFixed(2)),
      item_id_ep: product.styleNumber,
      item_name_ep: product.name,
      image_type: imageType,
      image_number: imageNumber,
      items: [item],
    },
  });
};

export enum SearchType {
  regular = 'regular search',
  noResults = 'no results search bar',
}

export type CustomEvent = {
  event_name: string;
  user_action?: string;
  nav_path?: string;
  item_id_ep?: string;
  item_name_ep?: string;
  search_type?: SearchType;
  params?: Record<string, any>;
};

export const AnalyticsCustomEvent = ({
  event_name,
  user_action,
  nav_path,
  search_type,
  params,
  item_id_ep,
  item_name_ep,
}: CustomEvent) =>
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name,
    event_params: {
      ...(user_action && { user_action }),
      ...(nav_path && { nav_path }),
      ...(search_type && { search_type }),
      ...(params && params),
      ...(item_id_ep && { item_id_ep }),
      ...(item_name_ep && { item_name_ep }),
      ...(params && params),
    },
  });

export const logoutEvent = (customerEmail, customerId, method) => {
  AnalyticsCustomEvent({
    event_name: AnalyticsEvents.LOGOUT,
    params: {
      method: method,
      is_guest: 'true',
      hashed_email: hashEmail(customerEmail),
      custom_user_id: customerId,
    },
  });
};

export const loginEvent = (hashedEmail, customUserId, url) => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.LOGIN,
    event_params: {
      method: getPageType(url),
      is_guest: 'false',
      hashed_email: hashEmail(hashedEmail),
      custom_user_id: customUserId,
    },
  });
};

export const searchAnalyticsEvent = (resultsNumber: string) => {
  const keys = [
    'search_type',
    'search_term',
    'suggested_section_click',
    'suggested_element_click',
    'user_action',
  ];

  const getItemLowerCase = (key: string) => {
    const value = window.sessionStorage.getItem(key);
    return value ? value.toLowerCase() : undefined;
  };

  const searchType = window.sessionStorage.getItem(keys[0]) ?? undefined;
  const searchTerm = window.sessionStorage.getItem(keys[1]) ?? undefined;
  const suggestedSectionClick = getItemLowerCase(keys[2]);
  const suggestedElementClick = getItemLowerCase(keys[3]);
  const userAction = window.sessionStorage.getItem(keys[4]);

  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.SEARCH,
    event_params: {
      search_type: searchType,
      search_term: searchTerm,
      results_number: resultsNumber,
      suggested_section_click: suggestedSectionClick,
      suggested_element_click: suggestedElementClick,
      user_action: userAction,
    },
  });

  keys.forEach(key => window.sessionStorage.removeItem(key));
};

export const headerNavigationClickEvent = ({ link }: MainNavItemType) => {
  if (link?.href) {
    const parts = categoryUrlToParts(link.href);
    AnalyticsCustomEvent({
      event_name: AnalyticsEvents.NAVIGATION_CLICK,
      user_action: 'header',
      nav_path: categoryPartsToParentNavigationClick(parts).toLowerCase(),
    });
  }
};

export const iconHeaderNavigationClickEvent = (icon: string) =>
  AnalyticsCustomEvent({
    event_name: AnalyticsEvents.NAVIGATION_CLICK,
    user_action: 'header',
    nav_path: `Icon:${icon}`.toLowerCase(),
  });

export const footerNavigationClickEvent = nav_path => {
  AnalyticsCustomEvent({
    event_name: AnalyticsEvents.NAVIGATION_CLICK,
    user_action: 'footer',
    nav_path: nav_path.toLowerCase(),
  });
};

/*
 *  header navigation
 */
const categoryUrlToParts = (url: string): string[] =>
  // '/women/clothing/plus-size' => ['Women', 'Clothing', 'Plus Sizes']
  url
    .split('/')
    .filter(Boolean) // remove any empty string parts (first one caused by leading slash)
    .map(x => capitalize(x.replace(/-/g, ' '))); // remove dashes from and capitalize words in each part

const categoryPartsToParentNavigation = (parts: string[]): string => {
  // '/men' => 'Shop:Men' (level 1) or '/men/shoes/suede' => 'Shop:Men:Shoes' (level 2+)
  if (parts.length > 1) {
    parts = parts.slice(0, -1); // remove the last part for categories greater than level 1 for reporting purposes
  }
  const reportingPrefix = 'Shop';
  return [reportingPrefix, ...parts].join(':');
};

const categoryPartsToParentNavigationClick = (parts: string[]): string => {
  const reportingPrefix = 'Shop';
  return [reportingPrefix, ...parts].join(':');
};

export const headerNavigationEvent = ({
  parentNavigation,
  linkClicked = '',
}: {
  parentNavigation: string;
  linkClicked?: string;
}) => {
  event(AnalyticsEvents.HEADER_LINK_CLICKED, {
    parentNavigation,
    linkClicked,
  });
};

export const categoryHeaderNavigationEvent = ({ link }: MainNavItemType) => {
  if (link?.href) {
    const parts = categoryUrlToParts(link.href);
    headerNavigationEvent({
      parentNavigation: categoryPartsToParentNavigation(parts),
      linkClicked: parts[parts.length - 1], // prefer last part to label
    });
  }
};

export const iconHeaderNavigationEvent = (icon: string) =>
  headerNavigationEvent({
    parentNavigation: `Icon:${icon}`,
    linkClicked: icon,
  });

export const getUserOrderInformation = (order: StandardOrder) => {
  return {
    order_email: lowerCaseOrBlank(order?.customer?.email),
    phone_number: lowerCaseOrBlank(order?.shipment?.address?.phone),
    address_fname: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.firstName
    ),
    address_lname: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.lastName
    ),
    address_street: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.address1
    ),
    address_state: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.stateCode
    ),
    address_postal: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.postalCode
    ),
    address_city: lowerCaseRemoveSpecialChars(order?.shipment?.address?.city),
    address_country: order?.shipment?.address?.countryCode,
  };
};

const isEmpty = value => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export const convertToGA4PaymentMethod = (
  paymentMethod: PaymentMethod
): PaymentMethodsGA4Analytics => {
  const paymentMethodMapping = {
    [PaymentMethod.AFTERPAY]: PaymentMethodsGA4Analytics.AFTERPAY,
    [PaymentMethod.ALIPAY]: PaymentMethodsGA4Analytics.ALIPAY,
    [PaymentMethod.AMAZON_PAY]: PaymentMethodsGA4Analytics.AMAZON_PAY,
    [PaymentMethod.AMBASSADOR_CARD]: PaymentMethodsGA4Analytics.AMBASSADOR_CARD,
    [PaymentMethod.APPLE_PAY]: PaymentMethodsGA4Analytics.APPLE_PAY,
    [PaymentMethod.COD]: PaymentMethodsGA4Analytics.CASH_ON_DELIVERY,
    [PaymentMethod.CREDIT_CARD]: PaymentMethodsGA4Analytics.CREDIT_CARD,
    [PaymentMethod.GIFT_CARD]: PaymentMethodsGA4Analytics.GIFT_CARD,
    [PaymentMethod.GOOGLE_PAY]: PaymentMethodsGA4Analytics.GOOGLE_PAY,
    [PaymentMethod.KLARNA]: PaymentMethodsGA4Analytics.KLARNA,
    [PaymentMethod.ONLINE_BANKING_IN]: PaymentMethodsGA4Analytics.NET_BANKING,
    [PaymentMethod.PAY_PAY]: PaymentMethodsGA4Analytics.PAY_PAY,
    [PaymentMethod.PAYPAL]: PaymentMethodsGA4Analytics.PAYPAL,
    [PaymentMethod.UPI]: PaymentMethodsGA4Analytics.UPI,
    [PaymentMethod.WALLET]: PaymentMethodsGA4Analytics.WALLETS,
    [PaymentMethod.ZIP_PAY]: PaymentMethodsGA4Analytics.ZIP_PAY,
    [PaymentMethod.WECHAT_PAY]: PaymentMethodsGA4Analytics.WECHAT_PAY,
    [PaymentMethod.ADYEN_COMPONENT]: PaymentMethodsGA4Analytics.ADYEN_COMPONENT,
    [PaymentMethod.ADYEN_PAYPAL]: PaymentMethodsGA4Analytics.PAYPAL,
    [PaymentMethod.KLARNA_ACCOUNT]: PaymentMethodsGA4Analytics.KLARNA_ACCOUNT,
    [PaymentMethod.KLARNA_PAYMENTS]: PaymentMethodsGA4Analytics.KLARNA_PAYMENTS,
    [PaymentMethod.NET_BANKING_DEPRECATED]:
      PaymentMethodsGA4Analytics.NET_BANKING,
    [PaymentMethod.PAYTM]: PaymentMethodsGA4Analytics.PAYTM,
    [PaymentMethod.SCHEME]: PaymentMethodsGA4Analytics.SCHEME,
    [PaymentMethod.STORE_CREDIT]: PaymentMethodsGA4Analytics.STORE_CREDIT,
    [PaymentMethod.UPI_COLLECT]: PaymentMethodsGA4Analytics.UPI_COLLECT,
    [PaymentMethod.AFTERPAY_TOUCH]: PaymentMethodsGA4Analytics.AFTERPAY_TOUCH,
    [PaymentMethod.RAZORPAY]: PaymentMethodsGA4Analytics.RAZORPAY,
  };

  return (
    paymentMethodMapping[paymentMethod] ||
    (paymentMethod && paymentMethod.split('_').join(' ').toLowerCase())
  );
};

export const transformListProductToAnalyticsItem = ({
  product,
  currency,
  quantity,
  categories,
  itemListId,
  itemListName,
  size,
  adjustments,
  index,
  creativeName,
  creativeSlot,
  promotionId,
  promotionName,
  discovery,
  assortmentAvailabilityItem,
  itemId,
  itemName,
}: ProductToTransform) => {
  const item_id =
    itemId ??
    ((product?.masterId &&
      (product.colorValue
        ? product.masterId + '_' + product.colorValue
        : product.masterId)) ||
      product?.id);

  const productPrice = product?.productPrice
    ? product.productPrice
    : product?.variantProduct?.productPrice;
  const price = productPrice
    ? getMinimalNumber(
        productPrice.price,
        productPrice.promotionPrice,
        productPrice.salePrice
      )
    : undefined;

  const coupon =
    adjustments && adjustments.length > 0
      ? adjustments.map(c => c.couponCode).join('|')
      : undefined;

  const discount = productPrice
    ? coupon && productPrice?.salePrice && productPrice?.promotionPrice
      ? productPrice.salePrice - productPrice.promotionPrice
      : 0
    : undefined;
  const saleDiscount = productPrice
    ? productPrice.price && productPrice.salePrice
      ? productPrice.price - productPrice.salePrice
      : 0
    : undefined;

  const itemAvailability = getItemAvailability(product?.displayOutOfStock);
  const availability = ['InStock', 'PreOrder'].includes(itemAvailability)
    ? 'true'
    : 'false';

  const formattedSize: Maybe<string> | undefined =
    addPrefixToAnalyticProp(size);

  const sizes = product?.sizeGroups
    ? getSizesFromSizeGroups(product.sizeGroups)
    : [];
  const assortmentAvailability =
    sizes && sizes.length > 0 ? getVariantAvailability(sizes) : undefined;

  const markedDown =
    productPrice && productPrice.price && productPrice.price
      ? `${!!(productPrice.salePrice < productPrice.price)}`
      : undefined;

  const discounted =
    productPrice && productPrice.promotionPrice && productPrice.price
      ? `${!!(productPrice.promotionPrice < productPrice.price)}`
      : undefined;

  const orderable =
    product?.orderable === true || product?.variantProduct?.orderable === true
      ? 'true'
      : product?.orderable === false ||
        product?.variantProduct?.orderable === false
      ? 'false'
      : undefined;

  //To check
  const variantId =
    product?.variantId ||
    product?.productId ||
    product?.variantProduct?.variantId ||
    undefined;
  const styleNumber = product?.styleNumber || undefined;

  const colorCodeFormatted =
    product && product.colorValue
      ? `a${
          product.colorValue ||
          (product.colors && product.colors[0].value) ||
          product.color
        }`
      : undefined;

  const full_price = productPrice?.price
    ? String(productPrice.price)
    : undefined;

  const sport = !isEmpty(product?.sportName) ? product.sportName : undefined;
  const gender = !isEmpty(product?.gender) ? product.gender : undefined;
  const ageGroup = !isEmpty(product?.ageGroup) ? product.ageGroup : undefined;
  const lineName = !isEmpty(product?.lineName) ? product.lineName : undefined;
  const collection = !isEmpty(product?.collectionMark)
    ? product.collectionMark
    : undefined;
  const division = !isEmpty(product?.division) ? product.division : undefined;
  const businessUnit = !isEmpty(product?.businessUnit)
    ? product.businessUnit
    : undefined;
  const businessSubSegment = !isEmpty(product?.businessSubSegment)
    ? product.businessSubSegment
    : undefined;
  const franchise = !isEmpty(product?.franchise)
    ? product.franchise
    : undefined;

  const item: ItemProductInterface = {
    item_id,
    item_name: itemName || product?.name || product?.productName,
    affiliation: 'PUMA',
    coupon,
    currency,
    discount: discount ? Math.round(discount * 100) / 100 : undefined,
    index,
    item_brand: 'PUMA',
    ...categories,
    item_list_id: itemListId,
    item_list_name: itemListName,
    item_variant: variantId,
    price,
    quantity,
    creative_name: creativeName,
    creative_slot: creativeSlot,
    promotion_id: promotionId,
    promotion_name: promotionName,
    full_price,
    sale_discount:
      saleDiscount !== undefined
        ? Number((saleDiscount * quantity).toFixed(2))
        : undefined,
    size: formattedSize,
    availability,
    assortment_availability:
      assortmentAvailability || assortmentAvailabilityItem,
    discounted,
    orderable,
    item_ean: product?.ean || product?.variantProduct?.ean,
    color: product?.colorName || product?.variantProduct?.colorName,
    style_number: styleNumber,
    marked_down: markedDown,
    discovery,
    sport,
    gender,
    age_group: ageGroup,
    line_name: lineName,
    collection,
    division,
    color_code: colorCodeFormatted,
    business_unit: businessUnit,
    business_subsegment: businessSubSegment,
    franchise,
    department: undefined,
    class: undefined,
  };

  return item;
};
