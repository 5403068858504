import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';

import { destroyStoredAuthData } from 'gql/exchanges/auth/cookie';
import { SUPPORTED_COUNTRY, SUPPORTED_LANGUAGE } from 'utils/constants';
import { useLogoutMutation } from '__generated__/graphql';
import { AuthState } from 'gql/exchanges/auth/authState';

import { useFeature } from './useFeature';

export type AuthContextState = AuthState;

export type AuthContextType = AuthContextState & {
  logout: () => void;
  setAuth: Dispatch<SetStateAction<AuthContextState>>;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

type ProviderProps = React.ReactNode & {
  auth: AuthContextState;
  setAuth: Dispatch<SetStateAction<AuthContextState>>;
  country: SUPPORTED_COUNTRY;
  language: SUPPORTED_LANGUAGE;
};

export const AuthProvider: React.FC<ProviderProps> = ({
  country,
  language,
  auth,
  setAuth,
  children,
}) => {
  const [, logout] = useLogoutMutation();
  const popoverLoginEnabled = useFeature('POPOVER_LOGIN_ENABLED');

  const clearOpluxDetectionPreferences = () => {
    window.localStorage.removeItem('skip-oplux-detection');
  };

  // On logout we delete all cookies then redirect the user to the homepage
  const handleLogout = useCallback(async () => {
    await logout({});
    destroyStoredAuthData(country);
    clearOpluxDetectionPreferences();
    if (popoverLoginEnabled && !window.location.pathname.includes('/account')) {
      window.location.reload();
      window.scrollTo(0, 0);
    } else {
      window.location.href = `/${country}/${language}`;
    }

    window.localStorage.removeItem('otpToken');
    // We only want to define this function on initial render
    // when both country and language will be defined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, setAuth, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};
