import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { MainNavQueryType } from 'groq/queries/MainNavQuery';
import { removeLocalePrefix } from 'utils/locale';

import {
  AppendNavEventCategoryArgs,
  useMainNavAnalytics,
} from './useMainNavAnalytics';
import { groqPreviewInput } from './useGroqQuery';
import { useAvailableNavItemFilter } from './useAvailableNavItemFilter';
import { useSiteConfig } from './useSiteConfig';

type MainNavAnalyticsFns = {
  appendCategory: (category: AppendNavEventCategoryArgs) => Promise<void>;
  removePreviousClick: () => void;
};
type MainNavContextValue = {
  mobileMenuOpen: boolean;
  navAnalytics: MainNavAnalyticsFns;
  selectedCategory: string;
  clickedCategory: string;
  setMobileMenuOpen: (open: boolean) => void;
  setSelectedCategory: (categoryId: string) => void;
  handleCategoryClick: (categoryId: string) => void;
  setShowSubMenu: (showSubMenu: boolean) => void;
  showSubMenu: boolean;
  navLinks?: MainNavQueryType;
  subMenuItems: MainNavQueryType;
  isRegisterOpen: boolean;
  setRegisterOpen: (open: boolean) => void;
  isLoginOpen: boolean;
  setLoginOpen: (open: boolean) => void;
  isForgotPasswordFormOpen: boolean;
  setForgotPasswordFormOpen: (open: boolean) => void;
};

const MainNavContext = createContext<MainNavContextValue>(
  {} as MainNavContextValue
);

type MainNavProviderProps = {
  children: React.ReactNode;
  navLinks?: MainNavQueryType;
};

export const MainNavProvider = ({
  children,
  navLinks,
}: MainNavProviderProps) => {
  const router = useRouter();
  const { locale } = useSiteConfig();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isRegisterOpen, setRegisterOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isForgotPasswordFormOpen, setForgotPasswordFormOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [clickedCategory, setClickedCategory] = useState('');
  const [previousPdp, setPreviousPdp] = useState('');
  const navAnalytics = useMainNavAnalytics();

  const subMenuItems = useAvailableNavItemFilter(
    navLinks,
    groqPreviewInput(router.query)
  );

  useEffect(() => {
    // close sub menu and clear category on page change
    setShowSubMenu(false);
    setSelectedCategory('');
    // we only want to re-run this if the path changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath.split('?')[0]]);

  const handleCategoryClick = (categoryId: string) => {
    setPreviousPdp('');
    setClickedCategory(categoryId);
  };

  // Remove highlight if mobileMenuOpen
  useEffect(() => {
    if (mobileMenuOpen) setClickedCategory('');
  }, [mobileMenuOpen]);

  // Remove highlight if user uses the back/forward button
  useEffect(() => {
    router.beforePopState(() => {
      setClickedCategory('');
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);

  const mainLinks = useMemo(
    () => navLinks?.children?.map(value => value?.link.href) || [],
    [navLinks]
  );

  useEffect(() => {
    const isCategoryPage = !!router.query?.category;
    const isPdp = router.asPath.includes('pd');
    const isHomePage =
      router.pathname === '/[country]/[language]' && !isCategoryPage;
    const url = removeLocalePrefix(router.asPath, locale);

    if (!isCategoryPage && !isPdp && (!mainLinks.includes(url) || isHomePage)) {
      setPreviousPdp('');
      setClickedCategory('');
      return;
    }

    if (isPdp) {
      if (!previousPdp || router.query.id === previousPdp) {
        setPreviousPdp(router.query.id as string);
      } else {
        // Remove highlight if user navigates inside PDP to another product
        setPreviousPdp('');
        setClickedCategory('');
      }
    } else {
      setPreviousPdp('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, router.asPath]);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [mobileMenuOpen]);

  return (
    <MainNavContext.Provider
      value={{
        mobileMenuOpen,
        navAnalytics,
        selectedCategory,
        clickedCategory,
        setMobileMenuOpen,
        setSelectedCategory,
        handleCategoryClick,
        setShowSubMenu,
        showSubMenu,
        subMenuItems,
        navLinks,
        setRegisterOpen,
        isRegisterOpen,
        setLoginOpen,
        isLoginOpen,
        isForgotPasswordFormOpen,
        setForgotPasswordFormOpen,
      }}
    >
      {children}
    </MainNavContext.Provider>
  );
};

export const useMainNavContext = () => useContext(MainNavContext);
