import React from 'react';

import { useTranslate } from 'hooks/useTranslations';
import Modal from 'ui/components/Modal';

import CloseButton from './CloseButton';

export const CvvModal = ({ handleClose }: { handleClose?: () => void }) => {
  const t = useTranslate();
  const fallbackHandleClose = () => null;

  return (
    <Modal
      variant="dark"
      onClickOutside={handleClose}
      className="w-full max-w-md px-8 pb-8 pt-9 relative"
      dataTestId="cvv-hint-modal"
    >
      <CloseButton
        className="absolute top-5 right-5 !mt-0"
        onClick={handleClose ?? fallbackHandleClose}
      />
      <div className="">
        <h1 className="block text-xl font-bold uppercase text-center mb-8">
          {t('whereToFindCVV')}
        </h1>

        <h2 className="block mt-8 mb-4 text-lg font-bold">Visa, MasterCard</h2>
        <img
          src="/assets/cvv_visa.png"
          className="w-full max-w-64"
          width="420"
          height="267"
        />
        <p className="mt-4">{t('cvvVisaInstructions')}</p>
        <div className="mt-12">
          <h2 className="block mt-4 mb-4 text-lg font-bold">
            American Express
          </h2>
          <img
            src="/assets/cvv_express.png"
            className="w-full max-w-64"
            width="420"
            height="267"
          />
          <p className="mt-4">{t('cvvAmexInstructions')}</p>
        </div>
      </div>
    </Modal>
  );
};
