import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  InputCheckbox,
  InputPassword,
  InputText,
  LiveRegion,
  Stack,
  Text,
} from '@cyber-cats/uds/elements';
import { useRouter } from 'next/router';

import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { useFormData } from 'hooks/useFormData';
import { dySignUpEvent } from 'utils/dynamicYield';
import { useLoginAndRegister } from 'hooks/useLoginAndRegister';
import { useSiteConfig } from 'hooks/useSiteConfig';
import Modal from 'ui/components/Modal';
import { Link } from 'ui/elements/Link';
import { NavigationFunction, OTPAuthStage } from 'ui/forms/OTPForm';
import { extractError, extractCode } from 'utils/extractError';
import { isServer } from 'utils/constants';

type RegisterForm = {
  phoneNo: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  emailList: boolean;
  terms: boolean;
  otpVerifyToken?: string | undefined;
};

export const SignupOTPForm = ({
  onSuccess,
  navigate,
}: {
  onSuccess?: () => void;
  navigate: NavigationFunction;
}) => {
  const t = useTranslate();
  const [validate] = useValidation();
  const { data, setValues } = useFormData();
  const { staticFeatures, country, language } = useSiteConfig();
  const { injectDynamicYieldScripts } = staticFeatures;

  const {
    register: registerField,
    handleSubmit,
    errors,
    formState,
  } = useForm<RegisterForm>({
    defaultValues: {
      emailList: true,
      phoneNo: data.phoneNo,
      terms: true,
    },
    mode: 'onChange',
  });

  const {
    register: [registerResult, register],
  } = useLoginAndRegister();
  const [otpToken, setOtpToken] = useState<boolean | undefined>(false);
  const router = useRouter();

  useEffect(() => {
    const isDesiredRoute =
      router.asPath ===
      `/${country.toLocaleLowerCase()}/${language}/account/login?action=register`;

    if (isDesiredRoute) {
      const validateOtpToken = !!window.localStorage.getItem('otpToken');
      setOtpToken(validateOtpToken);
    }
  }, [router.asPath, otpToken, country, language]);

  const onSubmit = async (data: RegisterForm) => {
    const result = await register({
      ...data,
      otpVerifyToken: window.localStorage.getItem('otpToken'),
    });
    if (extractCode(result) === 'LOGIN_ALREADY_EXISTS') {
      setValues({
        error:
          'This login is already in use. Please log in with your password.',
      });
      setValues({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNo,
      });

      navigate?.(OTPAuthStage.login_with_email);
    }

    if (!result.data?.register) return;

    if (onSuccess) onSuccess();

    if (onSuccess && injectDynamicYieldScripts) {
      dySignUpEvent(data.email);
    }
  };

  const error = extractError(registerResult) || data.error;

  return (
    <Stack asChild>
      <form
        data-test-id="login-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {error && (
          <LiveRegion type="assertive">
            <Alert
              dataTestId="register-otp-form-error"
              content={error}
              variant="error"
            />
          </LiveRegion>
        )}
        <InputText
          id="firstName"
          label={t('firstNameLabel')}
          dataTestId="auth-field-firstName"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.name,
          })}
          name="firstName"
          errorText={errors.firstName?.message}
          placeholder={t('firstNamePlaceholder')}
          required
        />
        <InputText
          id="lastName"
          label={t('lastNameLabel')}
          dataTestId="auth-field-lastName"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.name,
          })}
          name="lastName"
          errorText={errors.lastName?.message}
          placeholder={t('lastNamePlaceholder')}
          required
        />
        <InputText
          id="phoneNo"
          label={t('phoneLabel')}
          dataTestId="auth-field-phoneNo"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.phoneNumber,
          })}
          name="phoneNo"
          errorText={errors.phoneNo?.message}
          placeholder={t('phonePlaceholder')}
          type="tel"
          required
        />
        <InputText
          id="email"
          label={t('emailLabel')}
          dataTestId="auth-field-email"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.email,
          })}
          name="email"
          errorText={errors.email?.message}
          placeholder={t('emailPlaceholder')}
          required
        />
        <InputPassword
          id="password"
          label={t('passwordLabel')}
          dataTestId="auth-field-password"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.password,
          })}
          name="password"
          errorText={errors.password?.message}
          placeholder={t('passwordPlaceholder')}
          required
        />
        <Button
          dataTestId="auth-button-register"
          label={t('continue')}
          id="register-submit"
          loading={registerResult.fetching}
          disabled={registerResult.fetching || !formState.isValid}
          type="submit"
          dimmedReason={t('pleaseCorrectInputErrors')}
        />
        <InputCheckbox
          dataTestId="auth-field-emailList"
          id="emailList"
          name="emailList"
          ref={registerField}
        >
          <span className="font-bold">{t('emailListPlaceholder')}</span>
        </InputCheckbox>
        <InputCheckbox
          id="terms"
          name="terms"
          dataTestId="auth-field-toc"
          ref={registerField({
            validate: value => {
              if (!value) {
                return t('acceptTerms');
              }
            },
          })}
        >
          {t('reviewTerms')}
        </InputCheckbox>
        {errors.terms?.message && (
          <Text asChild dataTestId="toc-message">
            <p>{errors.terms.message}</p>
          </Text>
        )}
        {!data.phoneNo && !isServer && (
          <Modal
            variant="dark"
            dataTestId="verify-my-phone"
            className="max-w-xl p-6 border-none rounded-none origin-center"
          >
            <div className="flex flex-col space-y-6 p-4 sm:p-6">
              <div className="text-center">
                <span className="text-base text-gray-600 leading-none">
                  {t('verifyPhoneNoMessage')}
                </span>
              </div>
              <div className="text-center">
                <Link
                  id="verify-my-phone-btn"
                  href="/account/login?action=login"
                  as="button"
                  variant="black"
                  dataTestId="verify-my-phone-btn"
                >
                  {t('verifyPhoneNoCta')}
                </Link>
              </div>
            </div>
          </Modal>
        )}
      </form>
    </Stack>
  );
};
