import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { tw } from 'twind/css';
import { useRouter } from 'next/router';
import {
  Button,
  Icon,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from '@cyber-cats/uds/elements';

import {
  AnalyticsCustomEvent,
  AnalyticsEvents,
  headerNavigationEvent,
  iconHeaderNavigationClickEvent,
  iconHeaderNavigationEvent,
} from 'utils/analytics';
import { isServer } from 'utils/constants';
import { Link } from 'ui/elements/Link';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useSticky } from 'hooks/useSticky';
import { useTranslate } from 'hooks/useTranslations';
import { useLiveChat } from 'hooks/useLiveChat';
import { useMainNavContext } from 'hooks/useMainNav';
import { SearchForm } from 'ui/components/Search/SearchForm';
import { groqPreviewInput } from 'hooks/useGroqQuery';
import { useAvailableNavItemFilter } from 'hooks/useAvailableNavItemFilter';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { AuthAction } from 'ui/forms/AuthenticateForm';

import { NavMenuBar } from './NavMenuBar';
import { CartLink } from './CartLink';
import { PhoneNavLink } from './PhoneNavLink';
import { WishlistLink } from './WishlistLink';
import { PopoverForm } from './PopoverForm';

const QuickLinks = dynamic(() => import('ui/components/QuickLinks'), {
  ssr: false,
});

const MobileMenuScreen = dynamic(
  () => import('ui/components/MobileMenuScreen'),
  {
    ssr: false,
  }
);

const handleClickHome = () => {
  const parentNavigation = 'Home';

  headerNavigationEvent({ parentNavigation });

  AnalyticsCustomEvent({
    event_name: AnalyticsEvents.NAVIGATION_CLICK,
    user_action: 'header',
    nav_path: parentNavigation.toLocaleLowerCase(),
  });
};

const HomeButton = ({ shouldCenterIcon }: { shouldCenterIcon?: boolean }) => {
  return (
    <Link
      className={
        shouldCenterIcon ? 'absolute left-1/2 -translate-x-1/2' : 'block'
      }
      data-link-name="puma-logo"
      data-link-location="top-nav"
      data-test-id="main-nav-home-link"
      href="/"
      onClick={() => handleClickHome()}
    >
      <Icon
        name="puma-logo"
        size={shouldCenterIcon ? '5xl' : '4xl'}
        label="PUMA.com"
      />
    </Link>
  );
};

export const MainNav: React.FC<{
  minimal: boolean;
  mobileNavOffsetPx: number;
}> = props => {
  const t = useTranslate();
  const router = useRouter();
  const { localizeUrlPath } = useSiteConfig();

  const [linksHidden, setLinksHidden] = useState<boolean | undefined>(
    undefined
  );
  const hideNavChildren = !props.minimal && linksHidden === undefined;
  const { width, height } = useResizeObserver();
  const { stickyRef, stickyCss, combinedHeightByOrdinal } = useSticky(1);
  const {
    mobileMenuOpen,
    setMobileMenuOpen,
    navLinks,
    isRegisterOpen,
    setRegisterOpen,
    isLoginOpen,
    setLoginOpen,
    setForgotPasswordFormOpen,
    isForgotPasswordFormOpen,
  } = useMainNavContext();
  const { openLiveChatWindow, liveChatEnabled, renderInMainNav } =
    useLiveChat();

  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);

  const availableNavItems = useAvailableNavItemFilter(
    navLinks,
    groqPreviewInput(router.query)
  );

  const categories = availableNavItems?.children || [];
  const userMenuQuicklinks = navLinks?.userMenuQuicklinks || [];

  const setMainNavState = ({
    mobileMenuOpen = false,
    darkMode = true,
    loginOpen = false,
    registerOpen = false,
    accountMenuOpen = false,
    forgotPasswordFormOpen = false,
  }) => {
    setMobileMenuOpen(mobileMenuOpen);
    setDarkMode(darkMode);
    setLoginOpen(loginOpen);
    setRegisterOpen(registerOpen);
    setAccountMenuOpen(accountMenuOpen);
    setForgotPasswordFormOpen(forgotPasswordFormOpen);
  };

  useEffect(() => {
    setMainNavState({});
  }, [width, router]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id="nav-bar-sticky"
      className={tw(`relative z-20`, stickyCss)}
      ref={stickyRef}
    >
      <nav
        aria-label={t('mainNavLabel')}
        className={tw(
          props.minimal && 'mobile:px-4 tablet:px-6 desktop:px-8',
          darkMode
            ? 'bg-puma-black-100 text-white'
            : 'bg-white text-puma-black',
          hideNavChildren && 'children:invisible'
        )}
      >
        <div
          className={tw(
            'h-16 lg:h-20 flex items-center justify-between mx-auto overflow-y-visible relative',
            !darkMode && 'border-b',
            props.minimal
              ? 'max-w-screen-xl'
              : 'max-w-screen-3xl mobile:px-4 tablet:px-6 desktop:px-8'
          )}
          data-test-id="main-nav-bar"
        >
          {linksHidden && !props.minimal && (
            <button
              onClick={() =>
                setMainNavState({
                  mobileMenuOpen: !mobileMenuOpen,
                  darkMode: mobileMenuOpen,
                })
              }
              aria-label="button icon to open menu"
              data-test-id="account-button"
              data-link-name="close"
              data-link-location="mobile-nav"
              className="flex items-center"
              type="button"
            >
              <Icon
                data-test-id="main-nav-menu-icon"
                name={mobileMenuOpen ? 'close' : 'menu'}
                size="2xl"
              />
            </button>
          )}

          <div className="w-8 h-8">
            <HomeButton shouldCenterIcon={linksHidden && !props.minimal} />
          </div>

          {!props.minimal && (
            <>
              <NavMenuBar
                categories={categories}
                setLinksHidden={setLinksHidden}
              />
              <div
                className={tw(
                  'flex items-center justify-end gap-2 w-72',
                  !hideNavChildren && 'ml-5'
                )}
              >
                <div
                  className={
                    linksHidden ? 'absolute left-14 lg:left-16' : 'block'
                  }
                >
                  <SearchForm darkMode={darkMode} />
                </div>

                {liveChatEnabled && renderInMainNav && (
                  <Button
                    dataTestId="chat-button"
                    icon="chat"
                    onClick={() => openLiveChatWindow()}
                    mode="icon"
                    label=""
                  />
                )}
                <div className={linksHidden ? 'invisible' : 'visible'}>
                  <WishlistLink
                    darkMode={darkMode}
                    onClick={() => {
                      iconHeaderNavigationEvent('Wishlist');
                      iconHeaderNavigationClickEvent('Wishlist');
                    }}
                  />
                </div>

                {linksHidden && (
                  <Button
                    id="account-button"
                    label={t('myAccount')}
                    icon="person"
                    variant="ghost"
                    mode="icon"
                    invert={darkMode}
                    onClick={() => {
                      router.push(localizeUrlPath('/account'));
                      iconHeaderNavigationClickEvent('My Account');
                    }}
                  />
                )}

                <CartLink
                  darkMode={darkMode}
                  onClick={() => {
                    iconHeaderNavigationEvent('Cart');
                    iconHeaderNavigationClickEvent('Cart');
                  }}
                />
                {!linksHidden && (
                  <div className="relative">
                    <PopoverRoot
                      dataTestId="quicklinks-popout"
                      open={accountMenuOpen || isLoginOpen || isRegisterOpen}
                      onOpenChange={open =>
                        setMainNavState({ accountMenuOpen: open })
                      }
                      autoFocus="content"
                      side="bottom"
                      align="end"
                      size="base"
                      offset={-20}
                    >
                      <PopoverTrigger>
                        <Button
                          id="account-button"
                          label={t('myAccount')}
                          icon="person"
                          variant="ghost"
                          mode="icon"
                          invert={darkMode}
                          onClick={() =>
                            iconHeaderNavigationClickEvent('My Account')
                          }
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverBody>
                          <PopoverForm
                            action={AuthAction.login}
                            isOpen={isLoginOpen}
                            buttonText={t('login')}
                            onClose={() => setMainNavState({})}
                            handleClick={() =>
                              setMainNavState({
                                accountMenuOpen: true,
                              })
                            }
                          />
                          <PopoverForm
                            action={AuthAction.register}
                            isOpen={isRegisterOpen}
                            buttonText={t('joinUs')}
                            onClose={() => setMainNavState({})}
                            handleClick={() =>
                              setMainNavState({ accountMenuOpen: true })
                            }
                          />
                          <PopoverForm
                            action={AuthAction.reset_password}
                            isOpen={isForgotPasswordFormOpen}
                            buttonText={t('login')}
                            onClose={() => setMainNavState({})}
                            handleClick={() =>
                              setMainNavState({
                                loginOpen: true,
                              })
                            }
                          />
                          <QuickLinks
                            userMenuQuicklinks={userMenuQuicklinks}
                            hideMobileMenu={() =>
                              setMainNavState({
                                mobileMenuOpen: false,
                                accountMenuOpen: !accountMenuOpen,
                                darkMode: true,
                              })
                            }
                            setLoginOpen={() =>
                              setMainNavState({ loginOpen: true })
                            }
                            setRegisterOpen={() =>
                              setMainNavState({ registerOpen: true })
                            }
                            onClose={() => setMainNavState({})}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </PopoverRoot>
                  </div>
                )}
              </div>
            </>
          )}
          {props.minimal && !router.asPath.includes('showQuickRegister') && (
            <PhoneNavLink darkMode={darkMode} />
          )}
        </div>
        {mobileMenuOpen && (
          <div
            className={`relative flex-col flex bg-white overflow-x-hidden ${
              isRegisterOpen || isForgotPasswordFormOpen
                ? 'overflow-y-scroll'
                : 'overflow-y-hidden'
            }`}
            style={{
              height: isServer
                ? '100vh'
                : `${
                    window.innerHeight -
                    height -
                    props.mobileNavOffsetPx -
                    // Get the height of the top two (max) sticky banners
                    // ignoring the height of product filters since they
                    // are rendered below the mobile nav
                    (combinedHeightByOrdinal[1] || 0)
                  }px`,
            }}
          >
            <MobileMenuScreen
              userMenuQuicklinks={userMenuQuicklinks}
              isOpen={mobileMenuOpen}
              hideMobileMenu={() =>
                setMainNavState({
                  mobileMenuOpen: false,
                  accountMenuOpen: !accountMenuOpen,
                  darkMode: true,
                })
              }
            />
          </div>
        )}
      </nav>
    </div>
  );
};
