import React from 'react';
import { tw } from 'twind';
import { useRouter } from 'next/router';
import { Stack } from '@cyber-cats/uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { Link } from 'ui/elements/Link';
import Modal from 'ui/components/Modal';
import { AuthFormContextProvider } from 'hooks/useFormData';
import CloseButton from 'ui/components/CloseButton';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { useFeature } from 'hooks/useFeature';
import { useMainNavContext } from 'hooks/useMainNav';

import { useSiteConfig } from '../../hooks/useSiteConfig';

import { LoginWithEmailAndPasswordForm } from './LoginWithEmailAndPassword';
import { RegisterForm } from './AuthenticateRegisterForm';
import { ForgotPasswordForm } from './AuthenticateForgotPasswordForm';
import { QuickRegisterForm } from './AuthenticateQuickRegisterForm';

export enum AuthAction {
  register = 'register',
  login = 'login',
  reset_password = 'reset_password',
}

export interface AuthenticateTabsProps {
  from?: string | string[];
  action?: AuthAction;
  onSuccess?: () => void;
  resetOnOverlay?: boolean;
  showQuickRegistration?: boolean;
  isInModal?: boolean;
  isInPopover?: boolean;
  onClose?: () => void;
}

const tabStyle =
  'text-base md:text-xl whitespace-nowrap w-full flex justify-center items-center text-center cursor-pointer py-2 md:py-3 md:px-8 border-b-2 focus:ring-transparent focus:border-puma-gold';

const activeTabStyle = 'font-bold border-puma-black';

export const AuthenticateForm: React.FC<AuthenticateTabsProps> = ({
  from,
  action = AuthAction.login,
  onSuccess,
  resetOnOverlay,
  showQuickRegistration,
  isInModal,
  isInPopover,
  onClose,
}) => {
  const t = useTranslate();
  const router = useRouter();
  const { localizeUrlPath } = useSiteConfig();
  const { wishlist, swatch, size } = router.query;
  const { pageviewEventHasFired } = usePageEventsContext();
  const popoverLoginEnabled = useFeature('POPOVER_LOGIN_ENABLED');
  const { setForgotPasswordFormOpen } = useMainNavContext();

  const [selectedAction, setSelectedAction] =
    React.useState<AuthAction>(action);

  const fromSuffix = from ? `&from=${from}` : '';
  const swatchQuery = swatch ? `&swatch=${swatch}` : '';
  const sizeQuery = size ? `&size=${size}` : '';
  const wishlistQuery = wishlist ? `&wishlist=${wishlist}` : '';

  const handleActionChange = (action: AuthAction) => e => {
    e.preventDefault();
    if (!isInModal) {
      router.replace(
        localizeUrlPath(
          '/account/login?action=' +
            action +
            fromSuffix +
            swatchQuery +
            sizeQuery +
            wishlistQuery
        )
      );
    }
    setSelectedAction(action);
  };

  const showReset = selectedAction === AuthAction.reset_password;
  const activeTab =
    selectedAction === AuthAction.register
      ? AuthAction.register
      : AuthAction.login;
  const showAuthForm = !showQuickRegistration && (resetOnOverlay || !showReset);
  const showResetForm = showReset && !resetOnOverlay;
  const showResetOverlay = showReset && resetOnOverlay;

  return (
    <AuthFormContextProvider>
      {showQuickRegistration && <QuickRegisterForm onSuccess={onSuccess} />}
      {showAuthForm &&
        (!isInPopover ? (
          <>
            <h2 className="sr-only">{t('signIn')}</h2>
            <Stack items="start" gap="lg">
              <Stack direction="row" gap="none" justify="stretch">
                <Link
                  dataTestId="login-action-button"
                  onClick={handleActionChange(AuthAction.login)}
                  href={`/account/login?action=login${fromSuffix}`}
                  className={tw([
                    tabStyle,
                    activeTab === AuthAction.login && activeTabStyle,
                  ])}
                >
                  {t('login')}
                </Link>
                <Link
                  dataTestId="register-action-button"
                  onClick={handleActionChange(AuthAction.register)}
                  href={`/account/login?action=register${fromSuffix}`}
                  className={tw([
                    tabStyle,
                    activeTab === AuthAction.register && activeTabStyle,
                  ])}
                >
                  {popoverLoginEnabled ? t('joinUs') : t('createAccount')}
                </Link>
              </Stack>
              {activeTab === AuthAction.register ? (
                <Stack>
                  <RegisterForm
                    onSuccess={onSuccess}
                    pageviewEventHasFired={pageviewEventHasFired}
                    isInPopover={isInPopover}
                  />
                </Stack>
              ) : (
                <LoginWithEmailAndPasswordForm
                  onSuccess={() => {
                    onClose?.();
                    onSuccess?.();
                  }}
                  onForgotPasswordClick={() => {
                    setSelectedAction(AuthAction.reset_password);
                    setForgotPasswordFormOpen(true);
                  }}
                />
              )}
            </Stack>
          </>
        ) : action === AuthAction.register ? (
          <RegisterForm onSuccess={onClose} isInPopover={isInPopover} />
        ) : (
          <LoginWithEmailAndPasswordForm
            onSuccess={() => {
              onClose?.();
              onSuccess?.();
            }}
            onForgotPasswordClick={() => {
              setForgotPasswordFormOpen(true);
            }}
            isInPopover={isInPopover}
          />
        ))}
      {showResetForm && <ForgotPasswordForm isInPopover={isInPopover} />}
      {showResetOverlay && (
        <Modal
          className="w-full max-w-md px-8 pb-8 pt-9 relative"
          onClickOutside={() => {
            setSelectedAction(AuthAction.login);
            setForgotPasswordFormOpen(false);
          }}
          variant="dark"
          dataTestId="forgot-password-overlay-modal"
        >
          <CloseButton
            className="absolute top-5 right-5 !mt-0"
            onClick={() => {
              setSelectedAction(AuthAction.login);
              setForgotPasswordFormOpen(false);
            }}
            dataTestId="login-modal-close-btn"
          />
          <ForgotPasswordForm isInPopover={isInPopover} />
        </Modal>
      )}
    </AuthFormContextProvider>
  );
};
