import { apply, tw } from 'twind/css';
import { useRouter } from 'next/router';
import { omit } from 'lodash';

import { useSticky } from 'hooks/useSticky';
import { removeLocalePrefix } from 'utils/locale';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useGroqQuery } from 'hooks/useGroqQuery';
import { PageTypeQuery } from 'groq/queries/PageType';
import {
  InfoBannerWithCategoryExclusion,
  SecondGlobalBannerDocument,
  SecondGlobalBannerDocumentType,
} from 'groq/documents/SecondGlobalBannerDocument';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { getPageType } from 'utils/analytics';

import { InfoBanner } from './InfoBanner';

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    SecondGlobalBanner: SecondGlobalBannerDocumentType;
  }
}
function useSecondGlobalBannerQuery() {
  return useGroqQuery({
    query: SecondGlobalBannerDocument,
    operationName: 'SecondGlobalBanner',
  });
}

function usePageTypeQuery(url?: string) {
  return useGroqQuery({
    query: PageTypeQuery,
    operationName: 'PageType',
    variables: { url },
    pause: !url,
  });
}

const CATEGORY_PAGE_TYPES = {
  MarketingPage: 'other',
  CategoryPage: 'clp',
  category: 'plp',
} as const;

const AsideSecondGlobalBanner = (props: {
  banner: InfoBannerWithCategoryExclusion;
}) => {
  const { stickyRef, stickyCss } = useSticky(props.banner?.isSticky ? 2 : null);
  const { banner: infoBanner, ...rest } = props.banner;
  const gaTrackData: GaTrackData = {
    id: '',
    name: '',
    creative: 'SecondGlobalBanner',
  };
  usePromotionView(stickyRef, gaTrackData, true);
  return (
    <aside
      aria-label="Second Promo Banner"
      data-test-id="secondary-global-banner"
      className={tw(props.banner.isSticky && apply(`relative z-20`, stickyCss))}
      ref={stickyRef}
    >
      {infoBanner && <InfoBanner priority {...infoBanner} {...rest} />}
    </aside>
  );
};

export const SecondGlobalBanner = () => {
  const router = useRouter();
  const { locale } = useSiteConfig();
  const category = router.query.category as string[];
  const pagePath = removeLocalePrefix(router.asPath.split('?')[0], locale);

  const url = category && `/${category.join('/')}`;
  const [pageTypeResult] = usePageTypeQuery(url);
  const [result] = useSecondGlobalBannerQuery();

  const pageTypes = pageTypeResult.data?.content || {};

  const [categoryPage] = Object.entries(CATEGORY_PAGE_TYPES)
    .filter(([type]) => pageTypes[type] && pageTypes[type]._id)
    .map(([key]) => key);

  const urlForExcludedCategories = category
    ? `/${category.join('/')}`
    : pagePath || '/';

  const banners = result?.data?.content?.banners;
  const banner = banners
    ?.sort((a, b) => {
      // prioritize banners associated with source codes
      const aFirstSrc = a.campaign?.[0]?.sourceCode;
      const bFirstSrc = b.campaign?.[0]?.sourceCode;
      if (!aFirstSrc && bFirstSrc) return 1;
      if (aFirstSrc && !bFirstSrc) return -1;
      return 0;
    })
    .find(banner => {
      const isNotExcludedCoreLink = (banner.coreLinkExclusions || []).every(
        excludedCoreLink => !pagePath.startsWith(excludedCoreLink)
      );
      const isNotExcludedCategory = (banner.exclusions || []).every(
        excludedCategory => excludedCategory?.url !== urlForExcludedCategories
      );

      const pagesEntries = Object.entries(
        omit(banner.displayOnlyOn || {}, '_type')
      );

      const allPageTypes = pagesEntries.map(([key]) => key);

      const excludedPageTypes = pagesEntries
        .filter(([_, display]) => !display)
        .map(([key]) => key);

      const noPagesSelected = allPageTypes.length === excludedPageTypes.length;

      let pageType = getPageType(router.asPath) || '';
      if (pageType === 'plp') {
        pageType = CATEGORY_PAGE_TYPES[categoryPage];
      }

      const isNotExcludedPageType =
        noPagesSelected || !excludedPageTypes.includes(pageType);

      let isNotExcludedProduct = true;
      if (pageType === 'pdp') {
        const sku = `${router.query.id}_${router.query.swatch}`;
        isNotExcludedProduct = (banner.productsExclusions || []).every(
          ({ masterId, color }) => `${masterId}_${color}` !== sku
        );
      }

      return (
        isNotExcludedCoreLink &&
        isNotExcludedCategory &&
        isNotExcludedPageType &&
        isNotExcludedProduct
      );
    });

  if (!banner) return null;

  return <AsideSecondGlobalBanner banner={banner} />;
};
