import { UseFormMethods } from 'react-hook-form';
import { UseMutationState } from 'urql';
import { Alert, LiveRegion } from '@cyber-cats/uds/elements';
import { tw, css, style } from 'twind/style';
import { UdsResponsiveProp } from '@cyber-cats/uds/hooks';

import { FormFieldConfig, SubmitFunction } from 'ui/forms/custom/types';
import { CustomFormWrapper } from 'ui/forms/custom/components/CustomFormWrapper';
import CustomFormField from 'ui/forms/custom/components/CustomFormField';
import { extractError } from 'utils/extractError';

const layout = style({
  base: 'grid grid-cols-2 gap-4 children:(only-child:col-span-2 sm:col-auto col-span-2)',
  variants: {
    actionRow: { true: 'pt-3' },
  },
});

export type FormRowContainerProps = {
  row: FormFieldConfig[];
  children: React.ReactNode;
};

const FormRowContainer = ({ row, children }: FormRowContainerProps) => {
  const isActionsRow =
    row.filter(field => !['button', 'link'].includes(field.type)).length === 0;

  const fieldCollapseOverrides = row.map(
    (field, index) =>
      field.preventStack &&
      css`
        & > *:nth-child(${index + 1}) {
          @apply col-auto;
        }
      `
  );

  return (
    <div
      className={tw(
        layout({ actionRow: isActionsRow }),
        ...fieldCollapseOverrides
      )}
    >
      {children}
    </div>
  );
};

export type CustomFormProps = {
  isNested?: boolean;
  onSubmit?: (...props: any) => Promise<void>;
  result?: UseMutationState;
  form: UseFormMethods<any>;
  dataTestId?: string;
  disableResultError?: boolean;
  prefix?: string;
  submit?: SubmitFunction;
  config: Array<Array<FormFieldConfig>>;
  title?: string;
  gapY?: UdsResponsiveProp<
    | 'min'
    | 'max'
    | 'base'
    | 'none'
    | 'auto'
    | 'xs'
    | 'sm'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | 'px'
    | 'xxs'
    | '5xl'
    | undefined
  >;
};

export const CustomForm: React.FC<CustomFormProps> = ({
  result,
  form,
  prefix,
  config: formFields,
  disableResultError,
  isNested,
  onSubmit,
  submit,
  dataTestId,
  title,
  gapY,
}) => {
  const errorMessage = result && extractError(result);

  return (
    <CustomFormWrapper
      dataTestId={dataTestId}
      isNested={isNested}
      onSubmit={onSubmit}
      form={form}
      gapY={gapY}
    >
      {title && <h2 className="text-xl">{title}</h2>}
      {!disableResultError && (
        <LiveRegion type="assertive">
          {errorMessage && (
            <Alert
              variant="error"
              content={errorMessage}
              dataTestId={`${dataTestId}-error`}
            />
          )}
        </LiveRegion>
      )}
      {formFields &&
        formFields.map((row, i: number) => {
          return (
            <FormRowContainer row={row} key={i}>
              {row.map(field => (
                <CustomFormField
                  key={field.name}
                  fieldConfig={field}
                  result={result}
                  prefix={prefix}
                  submit={submit}
                />
              ))}
            </FormRowContainer>
          );
        })}
    </CustomFormWrapper>
  );
};
