var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"98829ea76670a63e4afca0f10ea6828cf2776306"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ALLOWED_DOMAINS = [/.+\.puma.com/i, /.+\.cybercat.cloud/i];

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.STAGE,
  release: `puma-web@${process.env.SHA}`,
  allowUrls: ALLOWED_DOMAINS,
  tracePropagationTargets: ALLOWED_DOMAINS,
  ignoreErrors: [
    // TODO(ResizeObserver): Track down if this is a real error
    // - Might be Cypress related https://github.com/quasarframework/quasar/issues/2233
    // - This fix: https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    // Triggered primarily by the IN Insider scripts patching fetch to do a second read of GQL response streams
    `The user aborted a request`,
  ],
  sampleRate: 0.1, // 10% of errors
  tracesSampleRate: 0.1, // 10% of transactions
});
